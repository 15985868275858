<template>
  <div class="blockInfo">
    <span v-if="title" class="bockTitle">{{ title }}</span>
     <date-picker
      :disabled="disabled"
       type="date"
        v-model="selectedDate"
        v-on:change="updateValue(value)"
      ></date-picker>

  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";


export default {
  name: "BlockInfo",
  components: { DatePicker, },
  props: {
    title: String,
    value: String,
    disabled: Boolean,
  },
  data() {
    return {
      format: "YYYY-MM-DDThh:mm:ss.sZ" , selectedDate: null,
    };
  }
  ,
  methods: {
    updateValue(value) {
      var dateUTC = new Date(this.selectedDate.getTime() - this.selectedDate.getTimezoneOffset() * 60 * 1000);    
      this.$emit("input",dateUTC.toJSON());
    },
  },
  created() {
    this.selectedDate = new Date(this.value);
  }
};
</script>

<style>
.mx-datepicker-popup {
  z-index: 99999 !important;
}
</style>
<style scoped>
.blockInfo {
  padding-top: 0.5em;
  padding-bottom: 1em;
  margin-bottom: 0.5em;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);

  position: relative;
}
.bockTitle {
  width: 50%;
}
.bockInput {
  border: none;
  display: block;
  width: 100%;
  padding: 0.5em 1em;
  font-size: 14px;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 2px rgb(124, 124, 124);
}
.bockInput:active,
.bockInput:hover,
.bockInput:focus {
  border: none;
  outline: none;
}

.buttonDelete {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
}
.mx-datepicker {
  width: 103%;
}
</style>