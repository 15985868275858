<template>
  <div>
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />

    <CRow>
      <CCol>
        <ListSurvey
         v-if="!loading"
          v-bind:tableItems="listSurvey"
          v-bind:countryCodes="countryCodes"
          v-bind:selectedCountry="country"
		      @survey-countries="changeCountry"
          @survey-edit="showCardSurveyEdit"
          @survey-delete="showCardSurveyDelete"
          @survey-new="showCardSurveyNew"
        />
      </CCol>
    </CRow>
        <transition name="slide-fade">
      <CardSurvey
        v-if="showSurveyCard"
        v-bind:survey="surveySelected"
        v-bind:countryCodes="countryCodes"
        v-bind:isNew="isNew"
        v-bind:isDelete="isDelete"
        v-bind:notification="notification"
        v-bind:notificationType="notificationType"
          @close-card="closeCardSurvey"
          @new-survey="newSurvey"
          @edit-survey="editSurvey"
          @delete-survey="deleteSurvey"
          @card-survey-error="showToastError"
      />
    </transition>
    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import ListSurvey from "../components/ListSurvey.vue";
import CardSurvey from "../components/CardSurvey.vue";

export default {
  name: "Surveys",
  components: {
    ListSurvey,
    CardSurvey
  },
  data() {
    return {
      loading: true,

      listSurvey: [],
      countryCodes: [],
      country: null,
      isNew: false,
      isDelete: false,
      notification: null,
      notificationType: null,

      surveySelected: null,
      showSurveyCard: false,
      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  async created() {
    // await this.loadData();
    await this.loadCountryData();
  },
  methods: {
    async loadCountryData() {
      var self = this;
      self.country = localStorage.getItem("userCountry") ? JSON.parse(localStorage.getItem("userCountry")) : "";
      try {
        self.countryCodes = [self.country];
        const res = await self.$http.get("apiConsole/console/getCountries");
        var countries = res.data.countries;
        self.countryCodes = [];
        countries.map((e) => {
          self.countryCodes.push(e.code);
        });
        self.changeCountry(self.countryCodes[0]);
      } catch (e) {
        if (e.response) {
          const res = e.response;
          console.log(res.data.message);
        }
        console.error(e);
      }
    },
    changeCountry(country) {
      console.log('changeCountry', country)
      var self = this;
      self.country = country;
      this.loadSurveys();
    },
    async loadSurveys() {
      var self = this;
      self.loading = true;
      const res = await self.$http.get(
        "apiConsole/console/getSurveys/" + self.country
      );
      self.listSurvey = res.data.surveys
      self.loading = false;
    },

    showCardSurveyEdit(event) {
      const self = this;
      self.showSurveyCard = true;
      self.surveySelected = event.surveySelected;
      self.surveySelected.interval_days = self.surveySelected.interval_hours / 24;
      self.countryCodes = self.countryCodes;
      self.isNew = false;

    },
    showCardSurveyDelete(event) {
      const self = this;
      self.showSurveyCard = true;
      self.surveySelected = event.surveySelected;
      self.surveySelected.interval_days = self.surveySelected.interval_hours / 24;
      self.countryCodes = self.countryCodes;
      self.isNew = false;
      self.isDelete = true;
      self.notificationType = "danger";
      self.notification = `You are about to eliminate the survey with title ${self.surveySelected.title}`;

    },
    showCardSurveyNew() {
      const self = this;
      self.showSurveyCard = true;
      self.surveySelected = {};
      self.countryCodes = self.countryCodes;
      self.isNew = true;

    },
    closeCardSurvey() {
      const self = this;
      self.showSurveyCard = false;

      self.isNew = false;
      self.isDelete = false;
      self.notification = null;
      self.notificationType = null;

      self.surveySelected = null;

      this.loadCountryData();

    },
    async newSurvey(newSurvey) {
      const self = this;
      try {
        newSurvey.interval_hours = newSurvey.interval_days * 24;
        const res = await self.$http.post("apiConsole/console/survey", {
          survey: newSurvey
        });
        self.loadCountryData();
        self.closeCardSurvey();

      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async editSurvey(editSurvey) {
      const self = this;
      try {
        editSurvey.interval_hours = editSurvey.interval_days * 24;
        const res = await self.$http.put("apiConsole/console/survey", {
          survey: editSurvey
        });
        self.loadCountryData();
        self.closeCardSurvey();

      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
    async deleteSurvey(deleteSurvey) {
      const self = this;
      console.log('deleteSurvey', deleteSurvey)
      try {
        deleteSurvey.interval_hours = deleteSurvey.interval_days * 24;
        const res = await self.$http.delete("apiConsole/console/survey", {
          data: { survey: deleteSurvey }
        });
        self.loadCountryData();
        self.closeCardSurvey();

      } catch (e) {
        if (e.response) {
          const res = e.response;
          self.showToastError(res.data.message);
        }
        console.error(e);
      }
    },
      showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>